import {ClassValue, clsx} from "clsx";
import {twMerge} from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function nameShortener(fullname: string, max: number) {
    return fullname.length > max ? fullname.split(' ')
    .map((v, i, a) => {
        return i < a.length - 1 ? v[0]?.toUpperCase() : v
    })
    .join('. ') : fullname
}

export function shorten(str: any, length: number = 60): string {
    if (!str || typeof str !== "string" || str.length <= length) {
        return str;
    }
    return str.substring(0, length) + '...';
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function hexToHSL(H: string) {
    let {r, g, b} = hexToInt(H);

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h: number,
        s: number,
        l: number;

    if (delta === 0)
        h = 0;
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    else if (cmax === g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return `${h} ${s}% ${l}%`;
}

function hexToInt(hex: string) {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
        r = parseInt("0x" + hex[1] + hex[1]);
        g = parseInt("0x" + hex[2] + hex[2]);
        b = parseInt("0x" + hex[3] + hex[3]);
    } else if (hex.length === 7) {
        r = parseInt("0x" + hex[1] + hex[2]);
        g = parseInt("0x" + hex[3] + hex[4]);
        b = parseInt("0x" + hex[5] + hex[6]);
    }

    return {r, g, b}
}

export function isBrightColor(hex: string) {
    let {r, g, b} = hexToInt(hex);

    return (r + g + b) > 382; // 255*3/2
}

function isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function deepMerge(target: any, source: any): any {
    if (!isObject(target) || !isObject(source)) {
        return source;
    }

    const output = {...target};

    Object.keys(source).forEach(key => {
        output[key] = isObject(source[key]) && (key in target)
            ? deepMerge(target[key], source[key])
            : source[key]
    });

    return output;
}

// resolver for factories
export const resolve = (val: any, fallback: any) =>
    (typeof val === 'function') ? val() : (val ?? fallback)
